<template>
  <div>
    <a-layout id="components-layout-demo-custom-trigger" style="min-height: 100vh">
      <a-layout-sider v-model="collapsed" collapsible :trigger="null">
        <!-- <div class="logo">
          <img src="../assets/logos.png">
          <h5>客户系统</h5>
        </div>

        <a-menu theme="dark" :default-selected-keys="['/index/dayprofit']" mode="inline" @select="handelClickLink">

          <a-menu-item key="/index/dayprofit" >
            <a-icon type="money-collect" />
            <span>APP日收益</span>
          </a-menu-item>

          <a-menu-item key="/index/monthprofit" >
            <a-icon type="money-collect" />
            <span>APP月收益</span>
          </a-menu-item>

        </a-menu> -->

        <div class="logo">
          <img src="../assets/logos.png">
          <h5 :class="collapsed ? 'opt' : ''">客户系统</h5>
        </div>
        <a-menu theme="dark" mode="inline" :default-selected-keys="keys" @select="handelClickLink">
         <a-menu-item key="/index" >
            <a-icon type="money-collect" />
            <span>APP日收益</span>
          </a-menu-item>
           <a-menu-item key="/index/monthprofit" >
            <a-icon type="money-collect" />
            <span>APP月收益</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout>
        <a-layout-header style="background: #fff; padding: 0" > 
          <headerView  @watchCollapsed="watchCollapsed"/> 
        </a-layout-header>

        <a-layout-content style="margin: 16px">

<!--          <a-breadcrumb style="margin: 16px 0">-->
<!--            <a-breadcrumb-item>APP收益</a-breadcrumb-item>-->

<!--          </a-breadcrumb>-->

          <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
            <transition name="fade-transform" mode="out-in">
              <a-spin :spinning="spinning" :delay="delayTime">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin/>
                <transition name="fade-transform" mode="out-in">
                  <router-view />
                 </transition>
              </a-spin>
            </transition>
          </div>

        </a-layout-content>

        <a-layout-footer style="text-align: center;"> <footerView /> </a-layout-footer>

      </a-layout>

    </a-layout>

  </div>
</template>

<script>

import footerView from '@/components/footerView'
import headerView from '@/components/headerView'
export default {
  name: "index",
  components: { footerView ,headerView },
  provide () {
    return {
      reload: this.reload
    }
  },
  data(){
    return{
      collapsed: false,
      spinning: false,
      delayTime: 100,
      keys:['index']
    }
  },
  created() {
      // console.log(this.$route.path)
     this.keys[0] = this.$route.path
     this.$router.push({path:this.keys[0]});
  },
  methods:{
    handelClickLink(item){
      // console.info(item)
      this.$router.push({path:item.key});

    },
    watchCollapsed(data) {
      // console.log(data)
      this.collapsed = data
    },
    reload(data) {
      // console.log(data)
      this.spinning = data
    },
  }
}
</script>

<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  /* background: rgba(255, 255, 255, 0.2); */
  margin: 16px;
}
.logo {
 width: 100%;
 padding: 16px;
 display: flex;
 align-items: center;
 overflow: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
 line-height: 32px;
}
.logo img{
  width: 32px;
  height: 100%;
  margin-right: 20px;
}
.logo h5{
  font-size: 16px;
  color: #fff;
  margin: 0;
  opacity: 1;
  font-weight: normal;
}
.logo .opt{
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}
 
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
 
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}
 
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
 
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
 
/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}
 
.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}
 
.breadcrumb-move {
  transition: all .5s;
}
 
.breadcrumb-leave-active {
  position: absolute;
}
</style>
