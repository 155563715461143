<template>
<div>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon
          class="trigger mar"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="collapseds"
        />
        <a-dropdown  class="head-menu">
          <span class="ant-dropdown-link" @click="e => e.preventDefault()">
            {{uname}} <a-icon type="down" />
          </span>
          <a-menu slot="overlay">
            <a-menu-item>
              <span  @click="quit" > <a-icon type="logout" /> &nbsp;退出登录</span>
            </a-menu-item>

          </a-menu>
        </a-dropdown>
      </a-layout-header>
      <!-- <a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#f00', minHeight: '280px' }"
      >
        Content
      </a-layout-content> -->
    </a-layout>

<!--  <a-menu  mode="horizontal" class="head-menu">
    <a-sub-menu>
      <span slot="title"><a-icon type="user" />{{uname}}</span>
      <a-menu-item key="login-out" @click="quit"><a-icon type="logout" />退出登录</a-menu-item>
    </a-sub-menu>
  </a-menu>-->
</div>
</template>

<script>
import {removeToken} from '@/utils/TokenCache'

export default {
  name: "headerView",
  data(){
    let uname = sessionStorage.getItem("unameDes");
    if(!uname){
      this.$router.push({path:'/' });
    }
    return{
      uname:uname,
      collapsed:false
    }
  },
  methods:{
    quit(){
      removeToken();
      sessionStorage.removeItem("unameDes")
      this.$router.push({path:'/' });
    },
    collapseds() {
      this.collapsed = !this.collapsed
      this.$emit('watchCollapsed',this.collapsed)
    }
  }

}
</script>

<style scoped>
.head-menu{
  float: right;
  margin-right: 20px;
}
.mar{
  margin-left: 12px;
  font-size: 18px;
}
</style>
