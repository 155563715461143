<template>
  <div>
    Ant Design ©2018 Created by Ant UED
  </div>
</template>

<script>
export default {
  name: "footerView"
}
</script>

<style scoped>

</style>
